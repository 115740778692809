.yvr-box {
    display: flex;
    flex-direction: column;
    padding: 2rem 2.5rem;
    border: 1px solid #E0E0E0;
    background: #FFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    z-index: 1;
}

.yvr-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.yvr-header {
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
    display: flex;
    background: #FFF;
}

.page-wrapper {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12rem 4rem 1rem 4rem;
}

.yavrio-logo {
    width: 12rem;
}

.yavrio-mark {
    width: 24rem;
    position: fixed;
    bottom: -2rem;
    left: -2rem;
    z-index: 0;
}

.qr-wrapper {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr; /* Two equal-width columns */
    grid-column-gap: 2rem; /* 2rem gap between columns */
    justify-content: center; /* Aligns the columns horizontally in the center */
    align-items: center;
    margin-bottom: 1rem;
    max-width: 24rem;
}

.button-group {
    display: flex;
    column-gap: 0.7rem;
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    margin-top: auto;
    justify-content: flex-end;
    flex-direction: row;
    background-color: #FFF;
    border-top: 1px solid #dedede;
    padding: 1.5rem 2rem 1.5rem 2rem;
}

.yvr-button {
    background: theme('colors.yavrio-green');
}

.yvr-button:hover {
    background: theme('colors.yavrio-green-dark') !important;
}

.yvr-button:disabled {
    background: theme('colors.gray.300');
}

.yvr-settings-box {
    min-width: 40rem;
}