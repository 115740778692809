@import url('https://fonts.googleapis.com/css2?family=Georama:wght@300;500;600;700&display=swap');
@import './style/yvr.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 218 25% 35%;
    --radius: 0rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9;
  }
}


html, body {
  background-color: #FAF9F9;
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Georama', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  color: #707070;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.7;
}

/* Standard heading styles */
h1, h2, h3, h4, h5, h6 {
  /* font-family: 'Arial', sans-serif; */ /* Choose an appropriate font */
  font-family: 'Georama', sans-serif;
  font-weight: 600; /* Adjust font weight as needed */
  color: theme('colors.yavrio-blue'); /* Set text color */
  margin-bottom: 1rem; /* Add some bottom margin for spacing */
  line-height: 1.3;
  font-smooth: anti-aliased;
}

/* Specific styles for each heading level (optional) */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

.yvr-box {
  border: 1px solid #E0E0E0;
  background: #FFF;
  padding: 0.5rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  z-index: 1;
}

.yvr-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.yvr-header {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  display: flex;
  background: #FFF;
}

.yvr-payment-summary p {
  margin-bottom: 0.1rem;
}

.page-wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12rem 4rem 1rem 4rem;
}

.yavrio-logo {
  width: 12rem;
}

.yavrio-mark {
  width: 24rem;
  position: fixed;
  bottom: -2rem;
  left: -2rem;
  z-index: 0;
}

.qr-wrapper {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr; /* Two equal-width columns */
  grid-column-gap: 2rem; /* 2rem gap between columns */
  justify-content: center; /* Aligns the columns horizontally in the center */
  align-items: center;
  margin-bottom: 1rem;
  max-width: 24rem;
}

.button-group {
  display: flex;
  column-gap: 0.7rem;
  position: sticky;
  bottom: 0;
  right: 0;
  width: 100%;
  margin-top: auto;
  justify-content: flex-end;
  flex-direction: row;
  background-color: #FFF;
  border-top: 1px solid #dedede;
  padding: 1.5rem 2rem 1.5rem 2rem;
}

.yvr-button {
  background: theme('colors.yavrio-green');
  border-radius: 100rem;
  padding: 0.3rem 1.5rem;
}

.yvr-button-secondary {
  background: #ffffff;
  border: #707070 1px solid;
  color: #707070;
  opacity: 0.6;
}

.yvr-button-secondary:hover {
  background: #ffffff !important;
  border: #707070 1px solid;
  color: #707070;
  opacity: 1;
}

.yvr-button:hover {
  background: theme('colors.yavrio-green-dark') !important;
}

.yvr-button:disabled {
  background: theme('colors.gray.300');
}

.yvr-settings-box {
  min-width: 40rem;
}

.yvr-button-red {
  background: white;
  color: #dc2626 !important;
  border: 1px solid #dc2626;
  border-radius: 100rem;
  padding: 0.3rem 1.5rem;
  color: #FFFFFF;
}

.yvr-button-red:hover {
  background: white !important;
  color: #b91c1c !important;
  border: 1px solid #b91c1c;
}

.yvr-button-red:disabled {
  color: white !important;
  border: none;
  background: theme('colors.gray.300');
}